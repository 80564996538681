@import '../../../../scss/theme-bootstrap';

.hero {
  &__content {
    padding: 25px 30px;
    height: 100%;
    justify-content: space-between;
    .content-block__content {
      height: auto;
    }
    .content-block__line--header {
      h1 {
        margin: 0;
      }
    }
    .hero--variant3 & {
      padding: 25px 0;
    }
    .hero--variant4 & {
      padding: 25px 0;
    }
    .sd-grid-formatter & {
      padding-top: 80px;
    }
  }
  &__content-wrapper {
    position: relative;
    height: auto;
    .#{$rdirection}-of-media &,
    .#{$ldirection}-of-media & {
      width: initial;
      @include breakpoint($landscape-up) {
        width: 32%;
      }
    }
  }
  &__wrapper {
    height: auto;
    &.#{$rdirection}-of-media {
      display: initial;
      @include breakpoint($landscape-up) {
        display: flex;
        flex-direction: row;
      }
    }
    &.#{$ldirection}-of-media {
      display: initial;
      @include breakpoint($landscape-up) {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
  &__media-wrapper {
    height: auto;
    .hero__media {
      height: 100%;
      .mantle-media-asset {
        height: 100%;
        img {
          min-height: 100%;
        }
      }
    }
  }
  &__callout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &:first-of-type {
      padding-top: 5px;
    }
    &:not(:last-of-type) {
      padding-bottom: 25px;
    }
  }
  &__callout-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
    .sd-grid-formatter & {
      margin-bottom: 0;
    }
  }
  &__callout-title {
    padding-#{$ldirection}: 15px;
    text-transform: uppercase;
    font-family: $font--subheading;
    font-size: 12px;
    font-weight: 900;
  }
  &__text-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
  }
  &__content-background {
    z-index: -1;
    img,
    picture,
    video {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  img,
  picture,
  video {
    max-width: 100%;
    height: auto;
  }
  .pc-hidden {
    display: block;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .mobile-flex-hidden {
    display: none;
    @include breakpoint($landscape-up) {
      display: flex;
    }
  }
  .text-cta {
    background: none;
    padding: 0;
    border: none;
    min-width: auto;
  }
}
